import { Component, OnInit } from '@angular/core';
import { TemplateService } from '../../core/services/template/template.service';
import { UserService } from '../../core/services/user/user.service';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { SeoService } from 'src/app/shared/services';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
  `,
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
    templateService: TemplateService,
    private userService: UserService
  ) {
    templateService.replaceComponent();
    this.setSeoValuesForPages();
  }

  ngOnInit(): void {
    this.userService.sendHeartBeat();
  }

  private setSeoValuesForPages(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
        tap(({ title, description }: Data) => {
          if (title && description) {
            this.seoService.updateTitle(title);
            this.seoService.updateDescription(description);
          } else {
            this.seoService.updateTitle('algoleague | competitive programming platform in Turkey');
            this.seoService.updateDescription(
              'algoleague is a competitive programming platform where algorithm enthusiasts of all levels practice their algorithmic thinking skills, and compete in contests both in teams and individually'
            );
          }
          this.seoService.setCanonicalTag(window.location.href);
        })
      )
      .subscribe();
  }
}
