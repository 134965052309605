import { END_POINTS_URL } from 'src/app/shared';
import { HttpClient } from '@angular/common/http';
import { IUpdateNotification } from '../models/interface/update-notification.interface';
import { Injectable, EventEmitter } from '@angular/core';
import { NotificationTypes } from '../models/types/notification.types';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  onChangeNotification: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _http: HttpClient) {}

  public getAll(
    eventType: NotificationTypes = 'None',
    skipCount = 0,
    maxResultCount = 10
  ): Observable<any> {
    //Todo: should  replace with proper API
    let URL = `${END_POINTS_URL.NOTIFICATION_API.NOTIFICATIONS}?SkipCount=${skipCount}&MaxResultCount=${maxResultCount}`;
    if (eventType != 'None') {
      URL += `&EventType=${eventType}`;
    }
    return this._http.get<any>(URL);
  }

  public getUnreadNotificationsCount(): Observable<any> {
    const URL = `${END_POINTS_URL.NOTIFICATION_API.NOTIFICATIONS}?isRead=false`;
    return this._http.get<any>(URL);
  }

  public updateOne(notificationId: string, payload: IUpdateNotification) {
    const URL = `${END_POINTS_URL.NOTIFICATION_API.NOTIFICATIONS}/${notificationId}`;
    return this._http.put(URL, payload);
  }

  public deleteNotificationById(notificationId: string): Observable<any> {
    const URL = `${END_POINTS_URL.NOTIFICATION_API.NOTIFICATIONS}/${notificationId}`;
    return this._http.delete(URL);
  }

  public getHubUrl(): any {
    return END_POINTS_URL.NOTIFICATION_API.HUB;
  }

  public readAll(): Observable<any> {
    const URL = `${END_POINTS_URL.NOTIFICATION_API.NOTIFICATIONS}/read-all`;
    return this._http.post(URL, {});
  }

  public deleteAll(): Observable<any> {
    const URL = `${END_POINTS_URL.NOTIFICATION_API.NOTIFICATIONS}/all`;
    return this._http.delete(URL);
  }
}
