import { RouterModule, Routes } from '@angular/router';

import { ListComponent } from './pages/list/list.component';
import { NgModule } from '@angular/core';
import { NotificationComponent } from './notification.component';

const routes: Routes = [
  {
    path: '',
    component: NotificationComponent,
    children: [
      {
        path: 'list',
        component: ListComponent,
        data: {
          title: 'Notifications | AlgoLeague',
          description:
            'Stay updated with the latest events and activities on AlgoLeague. Check your notifications to keep track of important updates and system alerts.',
        },
      },
      {
        path: '**',
        component: ListComponent,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotificationRoutingModule {}
