<ngb-carousel *ngIf='carousels?.length > 0' [showNavigationArrows]='showNavigationArrows'
  [showNavigationIndicators]='showNavigationIndicators'
  [interval]="interval"
  >
  <ng-container *ngFor='let carousel of carousels'>
    <ng-template ngbSlide>
      <div class='picsum-img-wrapper'>
        <img [src]='carousel.image' style='width: 100%;'
          [alt]="carousel.title ? (carousel.title | abpLocalization) : 'algoleague'">
      </div>
      <ng-container *ngIf='!carousel.redirectTo'>
        <div class='carousel-caption'>
          <h3 *ngIf='carousel.title'>{{carousel.title | abpLocalization}}</h3>
          <p *ngIf='carousel.description'>{{carousel.description | abpLocalization}}</p>
        </div>
      </ng-container>
      <ng-container *ngIf='carousel.redirectTo'>
        <div class='carousel-caption cursor-pointer' [routerLink]='carousel.redirectTo'>
          <h3 *ngIf='carousel.title'>{{carousel.title | abpLocalization}}</h3>
          <p *ngIf='carousel.description'>{{carousel.description | abpLocalization}}</p>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
</ngb-carousel>