<!--begin::Topbar-->
<ng-container *ngIf="extraSearchDisplay">
  <ng-container *ngIf="extrasSearchLayout === 'offcanvas'">
    <!--begin::Search-->
    <div class="topbar-item">
      <div class="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_search_toggle">
        <span
          [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'"
          cacheSVG="true"
          class="svg-icon svg-icon-xl svg-icon-primary"
        ></span>
      </div>
    </div>
    <!--end::Search-->
  </ng-container>

  <ng-container *ngIf="extrasSearchLayout === 'dropdown'" id="kt_quick_search_toggle">
    <div class="dropdown" id="kt_quick_search_toggle" autoClose="outside" ngbDropdown>
      <!--begin::Toggle-->
      <div class="topbar-item" ngbDropdownToggle>
        <div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
          <span
            [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'"
            cacheSVG="true"
            class="svg-icon svg-icon-xl svg-icon-primary"
          ></span>
        </div>
      </div>
      <!--end::Toggle-->

      <!--begin::Dropdown-->
      <div
        class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg"
        ngbDropdownMenu
      >
        <app-search-dropdown-inner></app-search-dropdown-inner>
      </div>
      <!--end::Dropdown-->
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="extrasNotificationsDisplay">
  <ng-container *ngIf="extrasNotificationsLayout === 'offcanvas'">
    <!--begin::Notifications-->
    <div class="topbar-item">
      <div
        class="btn btn-icon btn-icon-mobile btn-clean btn-lg mr-1 pulse pulse-primary"
        id="kt_quick_notifications_toggle"
      >
        <span
          [inlineSVG]="'./assets/media/svg/icons/Code/Compiling.svg'"
          cacheSVG="true"
          class="svg-icon svg-icon-xl svg-icon-primary"
        ></span>
        <span class="pulse-ring"></span>
      </div>
    </div>
    <!--end::Notifications-->
  </ng-container>

  <ng-container *ngIf="extrasNotificationsLayout === 'dropdown'">
    <!--begin::Notifications-->
    <div class="dropdown" ngbDropdown placement="bottom">
      <!--begin::Toggle-->
      <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" ngbDropdownToggle>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary">
          <span
            [inlineSVG]="'./assets/media/svg/icons/Code/Compiling.svg'"
            cacheSVG="true"
            class="svg-icon svg-icon-xl svg-icon-primary"
          ></span>
          <span class="pulse-ring"></span>
        </div>
      </div>
      <!--end::Toggle-->

      <!--begin::Dropdown-->
      <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-lg">
        <form>
          <app-notifications-dropdown-inner></app-notifications-dropdown-inner>
        </form>
      </div>
      <!--end::Dropdown-->
    </div>
    <!--end::Notifications-->
  </ng-container>
</ng-container>

<ng-container *ngIf="extrasQuickActionsDisplay">
  <ng-container *ngIf="extrasQuickActionsLayout === 'offcanvas'">
    <!--begin::Quick Actions-->
    <div class="topbar-item">
      <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1" id="kt_quick_actions_toggle">
        <span
          [inlineSVG]="'./assets/media/svg/icons/Media/Equalizer.svg'"
          cacheSVG="true"
          class="svg-icon svg-icon-xl svg-icon-primary"
        ></span>
      </div>
    </div>
    <!--end::Quick Actions-->
  </ng-container>
  <ng-container *ngIf="extrasQuickActionsLayout === 'dropdown'">
    <!--begin::Quick Actions-->
    <div class="dropdown" ngbDropdown placement="bottom">
      <!--begin::Toggle-->
      <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" ngbDropdownToggle>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span
            [inlineSVG]="'./assets/media/svg/icons/Media/Equalizer.svg'"
            cacheSVG="true"
            class="svg-icon svg-icon-xl svg-icon-primary"
          ></span>
        </div>
      </div>
      <!--end::Toggle-->
      <!--begin::Dropdown-->
      <div class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-lg" ngbDropdownMenu>
        <app-quick-actions-dropdown-inner></app-quick-actions-dropdown-inner>
      </div>
      <!--end::Dropdown-->
    </div>
    <!--end::Quick Actions-->
  </ng-container>
</ng-container>

<ng-container *ngIf="extrasCartDisplay">
  <ng-container *ngIf="extrasCartLayout === 'offcanvas'">
    <!--begin::Cart-->
    <div class="topbar-item">
      <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1" id="kt_quick_cart_toggle">
        <span
          [inlineSVG]="'./assets/media/svg/icons/Shopping/Cart3.svg'"
          cacheSVG="true"
          class="svg-icon svg-icon-xl svg-icon-primary"
        ></span>
      </div>
    </div>
    <!--end::Cart-->
  </ng-container>
  <ng-container *ngIf="extrasCartLayout === 'dropdown'">
    <!--begin::Cart-->
    <div class="dropdown" ngbDropdown placement="bottom">
      <!--begin::Toggle-->
      <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" ngbDropdownToggle>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span
            [inlineSVG]="'./assets/media/svg/icons/Shopping/Cart3.svg'"
            cacheSVG="true"
            class="svg-icon svg-icon-xl svg-icon-primary"
          ></span>
        </div>
      </div>
      <!--end::Toggle-->
      <!--begin::Dropdown-->
      <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-xl dropdown-menu-anim-up">
        <form>
          <app-cart-dropdown-inner></app-cart-dropdown-inner>
        </form>
      </div>
      <!--end::Dropdown-->
    </div>
    <!--end::Cart-->
  </ng-container>
</ng-container>

<ng-container *ngIf="extrasQuickPanelDisplay">
  <!--begin::Quick panel-->
  <div class="topbar-item">
    <div class="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_panel_toggle">
      <span
        [inlineSVG]="'./assets/media/svg/icons/Layout/Layout-4-blocks.svg'"
        cacheSVG="true"
        class="svg-icon svg-icon-xl svg-icon-primary"
      ></span>
    </div>
  </div>
  <!--end::Quick panel-->
</ng-container>

<ng-container *ngIf="extrasLanguagesDisplay">
  <app-language-selector style="margin-top: 17px"></app-language-selector>
</ng-container>

<ng-container *ngIf="!userService.userHasLoggedIn">
  <div class="topbar-item">
    <a
      class="w-auto btn btn-icon btn-clean btn-lg mr-1 bg-transparent-h mr-5 p-5"
      routerLink="/authorize/login"
      >{{ '::Login' | abpLocalization }}</a
    >
    <a
      class="w-auto btn btn-icon btn-clean btn-lg mr-1 btn-primary bg-transparent-h pr-2 pl-2 text-white p-5"
      routerLink="/authorize/register"
      >{{ '::SignUp' | abpLocalization }}</a
    >
  </div>
</ng-container>

<ng-container *ngIf="extrasUserDisplay">
  <ng-container *ngIf="extrasUserLayout === 'offcanvas'">
    <ng-container *ngIf="userService.userHasLoggedIn">
      <!--begin::User-->
      <div class="topbar-item">
        <div
          class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2"
          id="kt_quick_user_toggle"
        >
          <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
            >Hi,</span
          >
          <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{
            latestUsername
          }}</span>
          <span class="symbol symbol-lg-35 symbol-25 symbol-light-success">
            <span class="symbol-label font-size-h5 font-weight-bold">{{
              latestUsername | firstLetter
            }}</span>
          </span>
        </div>
      </div>
      <!--end::User-->
    </ng-container>
  </ng-container>

  <ng-container *ngIf="extrasUserLayout === 'dropdown'">
    <!--begin::User-->
    <ng-container *ngIf="userService.userHasLoggedIn">
      <div class="topbar-item mr-3 ml-3">
        <app-user-search></app-user-search>
        <app-un-read-notifications
          *ngIf="showUnreadNotifications == true"
        ></app-un-read-notifications>
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          class="w-auto btn btn-icon btn-clean btn-lg bg-transparent-h p-5"
        >
          {{ latestUsername }}
        </button>
        <span
          *ngIf="!profileImage"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          class="symbol symbol-35 symbol-light-success ml-4"
        >
          <span class="symbol-label font-size-h5 font-weight-bold">{{
            latestUsername | firstLetter
          }}</span>
        </span>
        <div
          *ngIf="profileImage"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          class="topbar-item"
          data-toggle="dropdown"
          data-offset="10px,0px"
        >
          <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 bg-transparent-h">
            <img class="h-30px w-30px rounded-sm" [src]="profileImage" alt="" />
          </div>
        </div>
      </div>

      <mat-menu #menu="matMenu">
        <ng-container *ngIf="userService.userHasLoggedIn">
          <ng-container>
            <!--begin::Header-->
            <div class="d-flex align-items-center p-8 rounded-top">
              <!--begin::Symbol-->
              <div class="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                <img
                  [src]="profileImage"
                  class="h-30px w-30px rounded-sm"
                  alt=""
                  *ngIf="profileImage"
                />
                <span class="symbol-label font-size-h5 font-weight-bold" *ngIf="!profileImage">{{
                  latestUsername | firstLetter
                }}</span>
              </div>
              <!--end::Symbol-->

              <!--begin::Text-->
              <div class="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                {{ latestUsername }}
              </div>

              <!--end::Text-->
            </div>
            <div class="separator separator-solid"></div>
            <!--end::Header-->
          </ng-container>

          <!--begin::Nav-->
          <div class="navi navi-spacer-x-0 pt-5">
            <!--begin::Item-->
            <a class="navi-item px-8 cursor-pointer" (click)="goToProfile()">
              <div class="navi-link">
                <div class="navi-icon mr-2">
                  <i class="bi bi-person-circle text-success"></i>
                </div>
                <div class="navi-text">
                  <div class="font-weight-bold">
                    {{ '::MyProfile' | abpLocalization }}
                  </div>
                  <div class="text-muted">
                    {{ '::MyProfileDescription' | abpLocalization }}
                  </div>
                </div>
              </div>
            </a>
            <!--end::Item-->

            <!--begin::Item-->
            <a class="navi-item px-8 cursor-pointer" routerLink="/profile/settings/notifications">
              <div class="navi-link">
                <div class="navi-icon mr-2">
                  <i class="bi bi-gear text-danger"></i>
                </div>
                <div class="navi-text">
                  <div class="font-weight-bold">
                    {{ '::AccountSettingsAndMore' | abpLocalization }}
                  </div>
                  <div class="text-muted">
                    {{ '::AccountSettingsDescription' | abpLocalization }}
                  </div>
                </div>
              </div>
            </a>
            <!--end::Item-->

            <!--begin::Footer-->
            <div class="navi-separator mt-3"></div>
            <div class="navi-footer px-8 py-5">
              <a class="btn btn-light-primary font-weight-bold cursor-pointer" (click)="logout()">
                <i class="bi bi-box-arrow-right"></i>
                {{ '::LogOut' | abpLocalization }}
              </a>
            </div>
            <!--end::Footer-->
          </div>
          <!--end::Nav-->
        </ng-container>
      </mat-menu>

      <!--end::Dropdown-->
    </ng-container>
    <!--end::User-->
  </ng-container>
</ng-container>

<!--end::Topbar-->
